<template>
    <b-container fluid>
        <b-row class="mb-2">
            <b-col>
                <b-form-input type="text" v-model="filter.num" placeholder="Номер реестра"/>
            </b-col>
            <b-col class="text-right">
                <b-button variant="primary" :to="{ name: 'create-delivery-register'}">Создать реестр</b-button>
            </b-col>
        </b-row>

        <b-table stacked="md" class="text-center" :items="entities" :fields="tableFields">
            <template v-slot:cell(actions)="data">
                <b-link class="ml-4" :to="{ name: 'delivery-register-editor', params: {id: data.item.id}}">
                    Просмотр
                </b-link>
                <b-link @click="printRegister(data.item.id)">Печать</b-link>
                <b-link @click="remove(data.item.id)">Удалить</b-link>
            </template>
        </b-table>
        <pagination :total-elements="pagination.totalElements" @change="paginationChange"/>
    </b-container>
</template>

<script>

    import Pagination from "../Pagination.vue";

    export default {
        name: "ShipmentBrowser",
        components: {Pagination},
        created() {
            this.getEntities();
        },
        watch: {
            filter: {
                handler() {
                    this.getEntities();
                },
                deep: true
            }
        },
        data() {
            return {
                entities: [],
                pagination: {
                    currentPage: 1,
                    totalElements: 0,
                    pageSize: 20,
                },
                filter: {
                    num: null,
                },
                tableFields: [
                    {
                        key: 'num',
                        label: 'Номер'
                    }, {
                        key: 'date',
                        label: 'Дата'
                    }, {
                        key: 'courierCode',
                        label: 'Курьерская служба'
                    }, {
                        key: 'closed',
                        label: 'Закрыт',
                        formatter: value => value?"Закрыт":"Открыт"
                    }, {
                        key: 'weight',
                        label: 'Вес (гр)'
                    }, {
                        key: 'volume',
                        label: 'Объём (куб.м.)'
                    }, {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
            }
        },
        methods: {
            getEntities() {
                this.$http.get(this.$root.apiUrl + '/api/delivery-registers',
                    {
                        params: {
                            page: this.pagination.currentPage - 1,
                            size: this.pagination.pageSize,
                            filter: JSON.stringify([
                                {
                                    key: "num",
                                    value: this.filter.num,
                                    operation: "="
                                },
                            ])
                        },
                        headers: this.$root.getHeaders()
                    }
                ).then(response => {
                    if (response.body.success) {
                        this.entities = response.body.list;
                        this.pagination.totalElements = response.body.pagination.totalElements;
                    } else {
                        alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                    }
                }, response => {
                    this.$root.handleErrorResponse(response);
                });
            },
            remove(id) {
                this.$http.delete(this.$root.apiUrl + '/api/delivery-register/' + id, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (response.body.success)
                            this.getEntities();
                        else
                            alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            },
            paginationChange(pagination) {
                this.pagination.currentPage = pagination.page;
                this.pagination.pageSize = pagination.size;
                this.getEntities();
            },
            translateStatus(status) {
                let tr = this.statusOptions.find(value => value.value === status);
                if (tr)
                    return tr.text;
                else
                    return status;
            },
            printRegister(id) {
                let fileType = "application/pdf";
                let fileName = id + ".pdf";
                let pdfWindow;
                if (!(window.navigator && window.navigator.msSaveOrOpenBlob)) {
                    pdfWindow = window.open("", "_blank");
                }
                this.$http.get(this.$root.apiUrl + '/api/getRegisterPrintForm/'+ id ,
                    {
                        headers: this.$root.getHeaders()
                    }
                ).then(response => {
                    if (response.body.success) {
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            let byteCharacters = atob(response.body.sticker);
                            let byteNumbers = new Array(byteCharacters.length);
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            let byteArray = new Uint8Array(byteNumbers);
                            let blob = new Blob([byteArray], {type: fileType});
                            window.navigator.msSaveOrOpenBlob(blob, fileName);
                        } else {
                            pdfWindow.document.write(
                                "<iframe width='100%' height='100%' src='data:" + fileType + ";base64, " + encodeURI(response.body.sticker) + "'></iframe>"
                            );
                        }
                    } else {
                        alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                    }
                }, response => {
                    this.$root.handleErrorResponse(response);
                });
            }
        }
    }
</script>

<style scoped>

</style>